import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import ButtonLink, {
  IButtonLink,
} from '@/components/atoms/ButtonLink/ButtonLink';
import Heading from '@/components/atoms/Heading/Heading';
import CardArticle, {
  ICardArticle,
} from '@/components/molecules/CardArticle/CardArticle';
import contentFulImageLoader from '@/lib/loaders/contentfulImageLoader';

/**
 * IButtonLinkWithHref Interface for a Button link with a href
 *
 * @augments IButtonLink
 * @interface IButtonLinkWithHref
 */
export interface IButtonLinkWithHref extends IButtonLink {
  /**
   * The button link url
   *
   * @memberof IButtonLinkWithHref
   * @member {string} href
   */
  href: string;
}

/**
 * IArticlesDisplay Interface for ArticlesDisplay component
 *
 * @interface IArticlesDisplay
 */
export interface IArticlesDisplay {
  /**
   * The title of the articles display.
   *
   * @memberof IArticlesDisplay
   * @member {string} title
   */
  title: string;
  /**
   * An array of card articles to display.
   *
   * @memberof IArticlesDisplay
   * @member {ICardArticle[]} articles
   */
  articles: ICardArticle[];
  /**
   * The optional button link.
   *
   * @memberof IArticlesDisplay
   * @member {IButtonLinkWithHref} [buttonLink]
   */
  buttonLink?: IButtonLinkWithHref;
  /**
   * The optional classes to be applied to the component.
   *
   * @memberof IArticlesDisplay
   * @default ''
   * @member {string} [classes]
   */
  classes?: string;
  /**
   * The optional imageClasses to be applied to the image inside a card.
   *
   * @memberof IArticlesDisplay
   * @default ''
   * @member {string} [imageClasses]
   */
  imageClasses?: string;
}

/**
 * ArticlesDisplay Organism for displaying articles
 *
 * @param {IArticlesDisplay} props - The props for the ArticlesDisplay component
 * @returns {React.FC<IArticlesDisplay>} ArticlesDisplay Component
 */
const ArticlesDisplay: React.FC<IArticlesDisplay> = ({
  title,
  articles,
  buttonLink,
  classes = '',
  imageClasses = '',
}: IArticlesDisplay) => {
  return (
    <div
      data-testid="articles-display"
      className={`max-w-[1360px] mx-auto ${classes}`}
    >
      <AnimateItem from={{ translateY: 20 }}>
        <div className="flex flex-col justify-between mb-8 space-y-4 sm:flex-row sm:space-y-0 sm:items-center md:mb-12">
          <Heading size="h3" font="petco" className="text-secondaryBase-400 ">
            {title}
          </Heading>
          {buttonLink && <ButtonLink {...buttonLink} />}
        </div>
      </AnimateItem>
      {articles && (
        <div className="grid sm:grid-cols-3 grid-cols-1 md:gap-x-8 gap-6">
          {articles.map((article, index) => (
            <div className="flex items-center justify-stretch" key={index}>
              <CardArticle
                {...article}
                imageClasses={imageClasses}
                loader={contentFulImageLoader}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ArticlesDisplay;
