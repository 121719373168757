import React from 'react';

/**
 * Extracts the videoId from a YouTube URL and returns an iframe element for
 * embedding the video.
 *
 * @param {string} url - The YouTube URL from which to extract the videoId.
 * @returns {React.ReactNode} - The iframe element for embedding the video, or
 *   null if the videoId is invalid or not found.
 */
const youtubeParser = (url: string): React.ReactElement | null => {
  // Extract videoId from the URL
  const match =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([v]?[a-zA-Z0-9_-]{11}).*/.exec(
      url
    );

  const videoId = match && match[7].length === 11 ? match[7] : null;
  if (videoId) {
    return (
      <iframe
        className="aspect-video w-full mb-5 rounded-3xl"
        title={`https://youtube.com/embed/${videoId}`}
        src={`https://youtube.com/embed/${videoId}`}
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  } else {
    return null;
  }
};

export default youtubeParser;
