import AbstractAnalyticsHandler, { IEvent } from './abstractAnalyticsHandler';
/**
 * EventLogHandler
 *
 * This will console log all the event data
 *
 * YOU WILL NEED TO UPDATE THE next.config.js TO ALLOW CONSOLE LOGS
 *
 * @augments AbstractAnalyticsHandler
 * @class
 */
export default class EventLogHandler extends AbstractAnalyticsHandler {
  /**
   * @memberof EventLogHandler
   * @param {IEvent} data - The data to be sent with the event
   */
  send(data: IEvent) {
    const analyticFeature = this.getAnalyticsFeature();

    /**
     * The data object to be sent to the console
     *
     * @memberof EventLogHandler
     * @member {object} dataObject
     */
    const dataObject: {
      /** The data associated with the event */
      data: object;
      /** The feature flags that are enabled */
      feature: string;
    } = {
      data: data.data,
      feature: analyticFeature,
    };

    console.log('EventLogHandler:', dataObject);
  }
}
