import AbstractAnalyticsHandler, { IEvent } from './abstractAnalyticsHandler';

/**
 * GoogleTagManagerHandler
 *
 * @augments AbstractAnalyticsHandler
 */
export default class GoogleTagManagerHandler extends AbstractAnalyticsHandler {
  /** @param {IEvent} data - The data to be sent with the event */
  send(data: IEvent) {
    const feature = this.getAnalyticsFeature();
    /** The data object to be sent to Google Tag Manager */
    const dataObject: { [key: string]: string | string[] } = {
      brand: this.brand,
      ...data.data,
      feature,
    };
    gtag('event', data.name, dataObject);
  }
}
