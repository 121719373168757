import AbstractAnalyticsHandler, {
  IEvent,
} from './handlers/abstractAnalyticsHandler';

/**
 * EventEmitter
 *
 * This will be used to dispatch each event to the appropriate handler
 *
 * @class
 */
export default class EventEmitter {
  /**
   * The handlers to be used for each event
   *
   * @memberof EventEmitter
   * @member {AbstractAnalyticsHandler[]} handlers
   */
  private handlers: AbstractAnalyticsHandler[];

  /** @param {AbstractAnalyticsHandler[]} handlers - Instances of handlerClasses */
  constructor(handlers: AbstractAnalyticsHandler[]) {
    this.handlers = handlers;
  }

  /**
   * The event handler
   *
   * @param {IEvent} data - Information about the event
   */
  handleEvent(data: IEvent) {
    this.handlers.forEach((handler) => {
      handler.send(data);
    });
  }
}
