import Paragraph from '@/components/atoms/Paragraph/Paragraph';

import Image, { ImageLoader } from 'next/legacy/image';
import Link from 'next/link';

/**
 * ICardArticle Interface for the Card Article Component
 *
 * @interface ICardArticle
 */
export interface ICardArticle {
  /**
   * Title of the Article for the Card
   *
   * @memberof ICardArticle
   * @member {string} title
   */
  title: string;
  /**
   * The Excerpt of the Article to Display
   *
   * @memberof ICardArticle
   * @member {string} excerpt
   */
  excerpt: string;
  /**
   * The Image of the Article to display
   *
   * @memberof ICardArticle
   * @member {string} image
   */
  image: string;
  /**
   * The optional classes for the component
   *
   * @memberof ICardArticle
   * @default ''
   * @member {string} [classes]
   */
  classes?: string;
  /**
   * The optional imageClasses for the component
   *
   * @memberof ICardArticle
   * @default ''
   * @member {string} [imageClasses]
   */
  imageClasses?: string;
  /**
   * Url of the article to link to
   *
   * @memberof ICardArticle
   * @member {string} url
   */
  url: string;
  /**
   * The optional target for the url
   *
   * @memberof ICardArticle
   * @default '_self'
   * @member {'_blank' | '_self' | '_parent' | '_top'} [target]
   */
  target?: '_blank' | '_self' | '_parent' | '_top';
  /**
   * The loader function for the image
   *
   * @memberof ICardArticle
   * @member {ImageLoader} [loader]
   */
  loader?: ImageLoader;
}

/**
 * Card Article The Card Article Component that links to an url and displays an
 * image, title and excerpt
 *
 * @param {ICardArticle} props - The props for the Card Article Component
 * @returns {React.FC<ICardArticle>} Card Article Component
 */
const CardArticle: React.FC<ICardArticle> = ({
  title,
  excerpt,
  image,
  classes = '',
  url,
  imageClasses = '',
  target = '_self',
  loader,
}: ICardArticle) => {
  return (
    <div
      data-testid="card-article"
      className={`w-full cursor-pointer [&:has(:focus-visible)]:ring-1 [&:has(:focus-visible)]:ring-focus-400 sm:rounded-[20px] md:rounded-3xl ${classes}`}
    >
      <Link
        href={url}
        className="w-full focus:ring-0 focus:ring-offset-0 focus-visible:ring-offset-0 focus-visible:ring-0 focus-visible:border-none focus-visible:outline-none"
        target={target}
      >
        <div
          className={`h-[218px] sm:h-[140px] md:h-72 relative rounded-2xl sm:rounded-[20px] md:rounded-3xl flex overflow-hidden ${imageClasses}`}
        >
          <Image
            data-testid="card-article-image"
            src={image}
            layout="fill"
            alt={`Image of ${title}`}
            objectFit="cover"
            className="scale-100 hover:scale-[102%] transition-transform duration-100"
            loader={loader}
            sizes="(max-width: 739px) 100vw, (max-width: 1199px) 33vw, 450px"
          />
        </div>
        <div className="w-full mt-4 md:mt-6">
          <span className="font-bold font-petco text-h5 text-mkNavy-400 line-clamp-2">
            {title}
          </span>
          <Paragraph size="body4" className="mt-3 font-petco line-clamp-2">
            {excerpt}
          </Paragraph>
        </div>
      </Link>
    </div>
  );
};

export default CardArticle;
