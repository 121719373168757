import getBasePath from '@/lib/utils/getBasePath/getBasePath';
import axios from 'axios';
import AbstractAnalyticsHandler, { IEvent } from './abstractAnalyticsHandler';

/**
 * KlaviyoEventHandler
 *
 * @augments AbstractAnalyticsHandler
 */
export default class KlaviyoEventHandler extends AbstractAnalyticsHandler {
  /** @param {IEvent} data - The data to be sent with the event */
  async send(data: IEvent) {
    const eventData = {
      data: {
        type: 'event',
        attributes: {
          properties: data.data.attributes,
          time: new Date(),
          metric: {
            data: {
              type: 'metric',
              attributes: {
                name: data.name,
              },
            },
          },
          profile: {
            data: {
              type: 'profile',
              attributes: data.data.profile,
            },
          },
        },
      },
    };
    axios.post(`${getBasePath()}/api/klaviyo/events`, eventData);
  }
}
