import Link, { LinkProps } from 'next/link';
import React, { HTMLProps, Ref } from 'react';

/**
 * IButtonLink Interface for the ButtonLink component
 *
 * @interface IButtonLink
 */
export interface IButtonLink {
  /**
   * The version of the button to display either light or dark
   *
   * @memberof IButtonLink
   * @member {'light' | 'dark'} variant
   */
  variant: 'light' | 'dark';
  /**
   * Whether the button is disabled
   *
   * @memberof IButtonLink
   * @default false
   * @member {boolean} [disabled]
   */
  disabled?: boolean;
  /**
   * The target of the link
   *
   * @memberof IButtonLink
   * @member {string} [target]
   */
  target?: string;
  /**
   * The children of the button
   *
   * @memberof IButtonLink
   * @member {string} children
   */
  children: string;
  /**
   * The color scheme used for the button link
   *
   * @memberof IButtonLink
   * @member {'primary' | 'secondary' | 'tertiary'} [color]
   */
  color?: 'primary' | 'secondary' | 'tertiary';
  /**
   * The ref of the button link
   *
   * @memberof IButtonLink
   * @member {Ref<HTMLAnchorElement>} [ref]
   */
  ref?: Ref<HTMLAnchorElement>;
}

/**
 * Button Link Props
 *
 * @type {ButtonLinkProps} Type That combines the IButtonLink interface with the
 *   HTMLProps<HTMLAnchorElement> & LinkProps from NextJS
 */
export type ButtonLinkProps = IButtonLink &
  LinkProps &
  HTMLProps<HTMLAnchorElement>;

/**
 * Button Base Font Properties This is the base font properties for the button.
 *
 * @constant
 */
const baseFont =
  'font-petco text-base font-bold border-b-[3px] pb-1.5 border-b-solid focus:ring-invisible transition-colors duration-200 ease-in-out';

/**
 * Variant Classes Map This map is used to map the variant prop to the
 * appropriate tailwind classes.
 *
 * @constant
 */
const variantClasses = {
  primary: {
    light:
      'text-secondaryBase-400 border-base-300 hover:text-base-300 hover:border-base-300',
    dark: 'text-neutral-100 border-neutral-100 hover:text-base-300 hover:border-base-300',
  },
  secondary: {
    light:
      'text-secondaryBase-400 border-mkPink-400 hover:text-secondaryBase-400 hover:border-secondaryBase-400',
    dark: 'text-mkPink-400 border-mkPink-400 hover:text-neutral-100 hover:border-neutral-100',
  },
  tertiary: {
    light:
      'text-secondaryBase-400 border-mkLightBlue-400 hover:text-secondaryBase-400 hover:border-secondaryBase-400',
    dark: 'text-mkLightBlue-400 border-mkLightBlue-400 hover:text-neutral-100 hover:border-neutral-100',
  },
};

/**
 * Wrapper Focus Styles This is the focus styles for the button.
 *
 * @constant
 */
const wrapperFocusStyles =
  'focus-visible:ring-1 focus-visible:ring-focus-400 focus-visible:rounded focus-visible:ring-offset-4 focus-visible:outline-0 focus-visible:outline-transparent';

/**
 * Link Disabled Styles This is the disabled styles for the button.
 *
 * @constant
 */
const disabledStyles = {
  light: 'text-neutral-500 border-neutral-500 cursor-not-allowed',
  dark: 'text-neutral-300 border-neutral-300 cursor-not-allowed',
};

const removeFocusStyles =
  'focus:outline-none focus:ring-0 focus-visible:ring-0 focus-visible:outline-none';

/**
 * ButtonLink - A button used to navigate to a different page.
 *
 * @param {IButtonLink} props - The props for the ButtonLink component
 * @returns {React.FC<ButtonLinkProps>} Button Link Component
 */
const ButtonLink: React.FC<ButtonLinkProps> = ({
  variant,
  href,
  as,
  disabled = false,
  children,
  replace,
  scroll,
  shallow,
  passHref,
  className = '',
  color = 'primary',
  ...rest
}: ButtonLinkProps) => {
  const variantStyles = disabled
    ? disabledStyles[variant]
    : variantClasses[color][variant];
  const cls = `${baseFont} ${variantStyles} ${wrapperFocusStyles}`;
  const dataTestId = `button-link-${variant}-${disabled}`;

  return (
    <div
      className={`${className} ${removeFocusStyles}`}
      data-testid={dataTestId}
    >
      <Link
        href={href}
        as={as}
        passHref={passHref}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        className={`${cls} ${removeFocusStyles}`}
        {...rest}
      >
        {children}
      </Link>
    </div>
  );
};

export default ButtonLink;
