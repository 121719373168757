import { googleTagManagerBrand } from '../constants/constants/analytics';
import EventEmitter from './eventEmitter';
import EventLogHandler from './handlers/eventLogHandler';
import GoogleTagManagerHandler from './handlers/googleTagManagerHandler';
import KlaviyoEventHandler from './handlers/klaviyoEventHandler';

/** Instances of each handler class */
const logEventHandler = new EventLogHandler(googleTagManagerBrand);
const gtmEventHandler = new GoogleTagManagerHandler(googleTagManagerBrand);
const klaviyoEventHandler = new KlaviyoEventHandler(googleTagManagerBrand);

/** Instances of each emitter */
export const logEventEmitter = new EventEmitter([logEventHandler]);
export const gtmEventEmitter = new EventEmitter([gtmEventHandler]);

/** DefaultEventEmitter to contain all handlers */
export const defaultEventEmitter = new EventEmitter([
  logEventHandler,
  gtmEventHandler,
]);

/** KlaviyoEventEmitter to contain Klaviyo related handlers */
export const klaviyoEventEmitter = new EventEmitter([
  logEventHandler,
  klaviyoEventHandler,
]);
